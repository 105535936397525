.color_azul {
  color: #DC842D !important;
}
.boton_sucursal{
  width:98%;
  padding: 10px 10px;
  border-radius: 20px;
}
.input_search_movil ,.carrito_movil ,.notificacion_movil{
  display:none;
}
.icon_cotificacion{
  font-size:30px;
}
@media screen and (max-width: 890px){
  .input_filtro{
    width:10rem;
  } 
}
@media screen and (max-width: 767px) {
  .input_search_movil ,.carrito_movil ,.notificacion_movil{
    display:block;
  }
  .boton_sucursal{
    width:auto;
    padding: 7px 7px;
    font-size: 13px;
    margin-left: 2px;
  }
  .icon_cotificacion{
    font-size:20px;
  }

}
