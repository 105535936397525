.fondo_scanear{
    background-color: #000000;
    width: 100%;
    height: 100%;
    //background-image: url('../images/fondoscan.png');
    position: fixed;
}
.titulo_scanear{
    width: 100%;
    font-size: 50px;
    color: white;
    margin-top: 40px;
    text-align: center;
    height: fit-content;
}

.container_scan{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
     
}
.content_img{
    position: absolute;
    // background-color: pink;
    height: 100%;
    width: 100%;
}

.cuadro_scan{
    background-color: white;
    min-width: 300px;
    width: 50%;
    height: auto;
    border-radius: 10px;
    padding: 2rem;
    height: fit-content;
    margin-top: 3rem;
}

.estilo_boton_scan{
    border-radius: 10px;
    background-color: white;
    color: #DC842D;
    padding: 10px;
    border: 1px solid #DC842D;

}

.body_scan{
    //  height: 70%;
    //  background-color: #828283;
    //  #E2E2E2


    //  border-radius: 10px;
    //  margin: 80px 40px 10px 40px;

     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;

     position: relative;
     height: 20rem;
    //  margin-bottom: 3rem;
     border-radius: 1rem;
    //  overflow: hidden;
}

.texto_botones_scan{
    font-size: 20px;
}

.borde_subrayado{
     border-style: dotted !important;
    //  background-color: #A7A6A6; 
     background-color: #E2E2E2; 
    // z-index: 100 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
     
}

// estilo para body_scan_barcode
.body_scan_barcode{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 20rem;
    border-radius: 1rem;
    overflow: hidden;
    border: 1px solid #828283;
    height: auto;
}

@media screen and (max-width: 1500px) {
    // .body_scan{
    //     height: 60%;
    // }
}

@media screen and (max-width: 1087px) {
    // .body_scan{
    //     margin-top:40px;
    //     margin-left: 10px;
    //     margin-right: 10px;
    // }
    .titulo_scanear{
     
        margin-top: 13px;
    }
    
}