a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}

.container_items_pedidos {
  position: relative !important;
}
.borderx {
  border: 1px solid red;
}

.estilo_col2 {
  padding-top: 130px;
}

.estilo_columna1_sucursal {
  padding-top: 86px;
  // padding-right: 10px;
}
.estilo_panel_detalle_pedido {
  position: absolute;
  right: 0px;
  top: 140px;
  width: auto;
  height: 500px;
  z-index: 10;
}
.btn_toggle_pedidos {
  position: relative;
}

.estilo_radio_estado_pedido_panel_principal {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.estilo_radio_estado_pedido_panel_principal_cabecera {
  border-top-left-radius: 20px;
}
.cajaDetPed {
  width: 100%;
  height: 150px;
  background-color: white !important;
  z-index: 10;
  margin-bottom: 10px;

  border-top: 1px solid #dc842d;
  border-left: 1px solid #dc842d;
  border-bottom: 1px solid #dc842d;
}

.cabecera_estado_pedido_panel_principal {
  background-color: #dc842d;
  width: 100%;
  height: 50px;
  color: white !important;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.titulo_cabecera_state_pedido {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.body_panel_state_pedido {
  width: 100%;
  min-height: 100px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.estilo_container_icono_state_pedido {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100px;
  padding-top: 15px;
}

.circulo_icono_state_pedidos {
  border-radius: 20px;
  width: 40px;
  height: 40px;
  //padding-top: 8px;
}

.color_morado {
  background-color: #32338e;
}

.color_plomo {
  background-color: #acacac;
}

.estilo_size_font_icon {
  font-size: 13px !important;
}

.estilo_icon_rigth {
  width: 20px;
  height: 80px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
}

.estilo_imagen_izquida_slider_producto {
  border: 1px solid #acacac;
  width: 215px;
  height: 250px;
  border-radius: 10px;
}

.estilo_imagenes_slider_seccion {
  display: flex;
  justify-content: center;
}

.bordex {
  width: 215px !important;
  height: 250px !important;
  margin-right: 10px;
  margin-left: 10px;
}

.style_block_text {
  /* border: 1px solid red;
     */
  position: relative;
  width: 330px;
  min-width: 300px;
  height: auto;
  min-height: 200px;
  padding: 70px 20px 20px 20px;
  z-index: 10;
}
.style_label_banner {
  font-size: 15pt;
  font-weight: bold;
  line-height: 18px;
}

.style_seccion_supermercado_farmacia {
  background-color: #f3f1f1;
  border-radius: 10px;
  min-height: 200px;
  min-width: 200px;
  width: 680px;
  display: flex;
}

.style_seccion_supermercado_farmacia_block1 {
  width: 50%;
  min-height: 200px;
}
.style_seccion_supermercado_farmacia_block2 {
  width: 50%;
  min-height: 200px;
  display: flex;
}

.sec_superm_farm_head {
  border-bottom: 2px solid #acacac;
  margin: 5px 10px 5px 10px;
  min-height: 50px;
}
.sec_superm_farm_body {
  padding: 5px;
}

.style_img_banner_sec2 {
  width: 100%;
  min-height: 280px;
}
.style_sec_list_comp {
  width: 30%;
}

.modal_full_scren {
  //  border:1px solid red;
}
.PhoneInputInput:disabled {
  background-color: white;
}

// modal registro usuario
.content_modal_registro {
  min-width: 25%;
}

@media screen and (max-width: 770px) {
  .style_seccion_supermercado_farmacia {
    flex-direction: column;
    width: auto;
    overflow: scroll;
  }
  .style_seccion_supermercado_farmacia_block1 {
    width: 100%;
  }

  .content_modal_registro {
    width: 100%;
  }
  .estilo_columna1_sucursal {
    padding-top: 30px;
  }
}
@media screen and (max-width: 570px) {
  .style_sec_list_comp {
    width: 50%;
  }
}
@media screen and (max-width: 450px) {
  .style_container_list_compras {
    flex-direction: column;
  }
  .style_sec_list_comp {
    width: 100%;
  }

  .style_button_list_comp {
    width: 100%;
  }
}
@media screen and (max-width: 390px) {
  .style_seccion_supermercado_farmacia_block2 {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.ReactModal__Body--open {
  overflow: hidden;
}

.bannerSwiper {
  .swiper-pagination-bullets {
    left: 50% !important;
    right: initial !important;
    transform: translateX(-50%);
    line-height: 1;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 2px;
    background-color: rgba(0, 0, 0, 0.3);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    bottom: 0 !important;
    width: unset !important;
  }
  .swiper-pagination-bullet {
    // text-align: center;
    // line-height: 20px;
    // font-size: 12px;
    // color: #000;
    // opacity: 1;
    background: rgba(0, 0, 0, 0.8);
    opacity: 1;
    // border: 1px solid #ffffff;
  }

  .swiper-pagination-bullet-active {
    color: #fff;
    background: #ffffff;
  }
}
