/*.estilo_link a:link {
    outline: none;
    text-decoration: none;
} 


.estilo_link a:visited { text-decoration:none; }
*/

.fondo_imagen{
    background-image: url('../images/fondopedidos.png');
    height: 100vh;
}

// a:link { text-decoration:none !important; } a:visited { text-decoration:none !important; }
.container_cardPedF{
    display: flex;
}

.card1{
    width: 50%;
}


.card2{
    width: 50%;
}
.container_item{
    padding: 1rem;
}



.estilo_number_paginacion{
    background-color: #DC842D;
    margin: 0px 2px;
    text-align: center;
    padding: 0px 2px;
    color: white;
    border-radius: 2px;
}


@media screen and (max-width: 740px) {

    .container_cardPedF{
             flex-direction: column;
    }

      .card1{
     width: 350px !important;
     }

    .card2{
          width: auto !important;
       }

}