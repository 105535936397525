.custom-radio-checkbox {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

/* Modificador para dar la imagen de radio */
.custom-radio-checkbox__show--radio {
    background-image: url(radio-uncheck.png);
}

/* Oculta input original */
.custom-radio-checkbox > .custom-radio-checkbox__input {
    display: none;
}

/* Radio personalizado usando <span> */
.custom-radio-checkbox > .custom-radio-checkbox__show {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: .5rem;
    background-size: cover;
}

/* Cambia el radio personalizado cuando es pulsado */
.custom-radio-checkbox > .custom-radio-checkbox__input:checked + .custom-radio-checkbox__show--radio {
    background-image: url(radio-check_white.png);
}

.custom-radio-checkbox__text{
    font-size: 15px;
    font-weight: 600;
}