
.style_panelB{

  padding: 0px 20px 0px 20px  ;
}

.data_sucursal_header_pdet{
  justify-content: flex-start;
}
.data_pedido_header_pdet{
  justify-content: flex-end;
}
.data_pedido_parraf_pdet{
  float: right;
}
@media screen and (max-width: 767px) {
  .style_panelB{
   margin-top: 20px;
    padding: 0px 0px !important;
  }
  .data_sucursal_pdet, .data_pedido_pdet{
    width: 100% !important;
  }

  .data_sucursal_header_pdet{
    justify-content: center !important;
  }
  .data_pedido_header_pdet{
    justify-content: center;
  }
  .data_pedido_parraf_pdet{
    float: left !important;
  }
}