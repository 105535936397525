$primario: #ed6a0f;
$primario_hover: #c04e0e;
$secundario: #0349ab;

$plomo_fuerte: #5a5a5a;
$plomo_fuerte_hover: #3A3A3A;
$plomo_suave: #9ca3af;

// $plomo_oscuro:#7C7C7C;#FFFFFF
$plomo_oscuro: #ababab;
$plomo_semi_oscuro: #e9e9e9;
$plomo_claro: #d0d3d4;

// global
.contenedor_flex {
  display: flex;
  flex-direction: row;
}

// detalle lista compra
// .contenedor_altura{
//     height: 80rem;
// }
// lista compra
.width_responsive {
  min-width: 20%;
  max-width: 20%;
}

.item_lista_compra_ellip {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 20%;
}

// MODAL CONTENT
.content_modal {
  width: 35rem;
  height: auto;
}

.title_responsivo {
  font-size: 1.7rem;
}

.title_carrito_responsivo {
  font-size: 1.5rem;
}

.contenedor_mobile {
  width: 25rem;
}

.content_modal_new {
  min-width: 35rem;
}

.content_vale {
  display: flex;
  justify-content: space-between;
}

// ESTILO SCROLL PERSONALIZADO
.container_scroll {
  background-color: white;
}

.container_scroll::-webkit-scrollbar {
  background-color: $plomo_claro;
  border-radius: 10px;
  width: 10px;
}

.container_scroll::-webkit-scrollbar-thumb {
  background-color: $plomo_oscuro;
  border-radius: 10px;
  cursor: default;
}

.container_scroll::-webkit-scrollbar-track {
  display: none;
  background: #ffffff;
}

/* ESTILO PARALE CHECK ROUNDED */

.container_sucursal_ini {
  max-width: 720px;
}

.round {
}

.round label {
  background-color: $primario;
  border: 1px solid $primario;
  border-radius: 5px;
  cursor: pointer;
  height: 28px;
  right: 0;
  position: relative;
  top: 0;
  width: 28px;
}

.round:hover label {
  background-color: $primario_hover;
  border: 1px solid $primario_hover;
}

.round.deshabilitar label {
  background-color: $plomo_fuerte;
  border: 1px solid $plomo_fuerte;
}

.round.deshabilitar:hover label {
  background-color: $plomo_fuerte_hover;
  border: 1px solid $plomo_fuerte_hover;
}

.round label:after {
  // border: 2px solid #fff;
  border: 2px solid #ffffff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  display: none;
}

.round input[type="checkbox"]:checked + label {
  background-color: $primario;
  color: white;
  border-color: $primario;
}

.round:hover input[type="checkbox"]:checked + label {
  background-color: $primario_hover;
  border-color: $primario_hover;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.containerStyle2Sucursal {
  border-radius: 2rem;
  width: 900px;
  overflow: auto;
  background: #fff;
}

.containerStyle3Sucursal {
  border-radius: 2rem;
  width: 900px;
}

// sucursal
.responsive_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.container_lista_compra_compartida .subcontenedor_mobile {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

// producto
.descripcion_producto {
  min-height: 25px;
  max-height: 25px;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

// nuevo disenio hiper
.content_title {
  min-height: 52px;
}

// carrito
.col_uno,
.col_dos {
  width: 50%;
}

.fontzize_text_carrito {
  font-size: 17px;
}

.icon_indice {
  font-size: 1.3rem;
}

.fontzize_label {
  font-size: 15px;
  font-weight: 600;
}

.btn_entrega {
  max-width: 18rem;
}

.altura_carrito_default {
  min-height: 738px !important;
  height: 738px !important;
}

.contenedor_image {
  min-height: 8rem;
}

.content_card_footer {
  background: white;
  margin-bottom: 2rem;
  padding: 0.5rem 1.5rem 1rem 1.5rem;
  width: 100%;
  .content_child_footer {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
}

.responsive_carrito {
  display: flex;
  flex-direction: row;
}

.container_buttons {
  height: 4rem;
}

.product_price {
  width: 87px;
}

@media screen and ( max-width: 1209px) {
  .containerStyle2Sucursal {
    width: 700px;
  }
  .containerStyle2Sucursal .containerStyle3Sucursal {
    width: 800px;
  }
}

@media screen and (max-width: 1109px) {
  .containerStyle2Sucursal .containerStyle3Sucursal {
    width: 700px;
  }
}

@media screen and (max-width: 1009px) {
  .containerStyle2Sucursal {
    width: 600px;
  }
}

@media screen and (max-width: 991px) {
  .ancho_container_carrito {
    width: 421px !important;
  }
}

@media screen and (max-width: 960px) {
  .containerStyle3Sucursal {
    width: 700px;
  }
}

@media screen and (max-width: 915px) {
  .contenedor_sucursal_tipoentrega {
    flex-direction: column;
    width: 100% !important;
  }
  .container_sucursal_ini {
    max-width: 980px !important;
  }
  .containerStyle2Sucursal {
    width: 100% !important;
  }
}

@media screen and (max-width: 767.98px) {
  .responsive_carrito {
    flex-direction: column;
  }
  .content_card_footer {
    padding: 0.5rem 1.5rem 1rem 1.5rem;
    .content_child_footer {
      flex-direction: row;
    }
  }
  .contenedor_flex {
    display: flex;
    flex-direction: column;
  }
  .contenedor_mobile {
    width: 100%;
  }
  .contenedor_mobile_scroll {
    overflow: auto;
  }
  .mobile_width_fit {
    width: fit-content;
  }
  .width_responsive {
    min-width: 50%;
  }
  .content_modal {
    min-width: 20rem;
    width: 100%;
  }
  .col_uno,
  .col_dos {
    width: 100%;
  }
  .margin_button_movil {
    margin-bottom: 2rem;
  }
  .title_responsivo {
    font-size: 0.8rem;
  }
  .title_carrito_responsivo {
    font-size: 1rem;
  }
  .icon_indice {
    font-size: 1.2rem;
  }
  .fontzize_label {
    font-size: 14px;
    font-weight: 500;
  }
  .btn_entrega {
    max-width: 100%;
  }
  .content_vale {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .content_modal_new {
    min-width: 20rem;
    max-width: 20rem;
  }
}

@media screen and (max-width: 754px) {
  .containerStyle3Sucursal {
    width: 100vw;
  }

  .round label {
    height: 24px;
    width: 24px;
  }

  .round label:after {
    height: 5px;
    left: 6px;
    top: 7px;
    width: 10px;
  }
}

@media screen and (max-width: 563px) {
  .texto_sucursal {
    font-size: 28px;
  }
  .width_responsive {
    min-width: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .contenedor_flex {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 450px) {
  .container_lista_compra_compartida {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .subcontenedor_mobile {
    padding-left: 0px !important;
    padding-right: 10px !important;
  }
}

@media screen and (max-width: 290px) {
  .responsive_grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
