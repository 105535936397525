.estilo_modal_fav{
   // position: absolute;
    min-height: 300px;
    height: auto;
   // width: 500px;
    min-width: 400px;
    max-width: 600px;

    z-index: 10;
   /* border: 2px solid #ccc;*/
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
}
.estilo_caja_myfav{
 width: 100%;
 height: 100%;
 border: 2px solid red;
}
@media screen and (max-width: 627px){
    .estilo_modal_fav{
         min-height: 300px;
         height: auto;
         min-width: 320px;
         max-width: 320px;
         z-index: 10;
        /* border: 2px solid #ccc;*/
         background-color: white;
         border-radius: 10px;
         overflow: hidden;
     }
}