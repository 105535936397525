
.contenedorPadIremCP{
  min-width: 432px !important;
}
.contenedorItemCP{

}

.styel_itemCP{
  min-width: 143px !important;
}
@media screen and (max-width: 600px) {



}