.product-detail-images {
    
      
      .swiper {
        width: 100%;
        height: 100%;
      }
      
      .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
      
        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      // .swiper-slide img {
      //   display: block;
      //   width: 100%;
      //   height: 100%;
      //   object-fit: cover;
      // }
      
      body {
        background: #000;
        color: #000;
      }
      
      .swiper {
        width: 100%;
        height: 300px;
        margin-left: auto;
        margin-right: auto;
      }
      
      .swiper-slide {
        background-size: cover;
        background-position: center;
      }
      
      .mySwiper2 {
        height: 100%;
        width: 100%;
      }
      
      .mySwiper {
        height: 100%;
        box-sizing: border-box;
        // padding: 10px 0;
        // width: 20%;
      }
      
      .mySwiper .swiper-slide {
        width: 100%;
        height: 100%;
        opacity: 0.4;
      }
      
      .mySwiper .swiper-slide-thumb-active {
        opacity: 1;
      }

      .mySwiper .swiper-slide img, .mySwiper3 .swiper-slide img{
        padding: 4px;
      }

      .mySwiper .swiper-slide-thumb-active img, .mySwiper3 .swiper-slide-thumb-active img{
        border: 2px solid rgb(237, 106, 15);
        border-radius: 0.5rem;
      }
      
      // .swiper-slide img {
      //   display: block;
      //   width: 100%;
      //   height: 100%;
      //   object-fit: cover;
      // }
      .swiper-pagination-fraction {
        width: fit-content;
        padding: 2px 8px;
        background: rgb(237, 106, 15);
        color: #fff;
        border-radius: 8px;
        right: 16px;
        left: unset;
      }

      .mySwiper4 {
        height: 100%;
        width: 100%;
      }
      
      .mySwiper3 {
        height: 100%;
        box-sizing: border-box;
        padding: 10px 0;
      }
      
      .mySwiper3 .swiper-slide {
        width: 25%;
        height: 100%;
        opacity: 0.4;
      }
      
      .mySwiper3 .swiper-slide-thumb-active {
        opacity: 1;
      }
}
